<template>
	<div>
		<a-row>
			<a-button type="primary" class="btn" @click="$refs.searchListTable.initTable(1)" v-t.preserve="'operate.search'"></a-button>
			<a-button type="primary" class="btn" @click="showRoleModal('add')">Add Code</a-button>
		</a-row>
		<search-list-table ref="searchListTable" :columns="columns" :initDataSource="initDataSource">
			<template slot="action" slot-scope="{ columnData }">
				<a @click="handleDetail(columnData)">{{ $t('page.checkDetail') }}</a>
			</template>
		</search-list-table>
		<!-- 弹窗 -->
		<a-modal
			v-drag-modal
			v-model="dictionary.show"
			:title="$t(dictionary.type === 'add' ? 'page.addCode' : 'page.editCode')"
			:destroyOnClose="true"
			:confirm-loading="dictionary.loading"
			width="800px"
			@ok="handleRoleModalOk"
		>
			<a-form-model
				:ref="dictionaryModal.form"
				:rules="dictionaryModal.rules"
				:model="dictionaryModal.data"
				:labelCol="dictionaryModal.labelCol"
				:wrapperCol="dictionaryModal.wrapperCol"
			>
				<a-form-model-item label="CodeName" prop="codeName">
					<a-input v-model="dictionaryModal.data.codeName"></a-input>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>
<script>
import { apiGetCodeList, apiCreateCode } from '@/api/code'
export default {
	data() {
		return {
			dictionary: {
				show: false,
				type: 'add',
				loading: false
			},
			dictionaryModal: {
				form: 'codeModalForm',
				labelCol: { span: 4 },
				wrapperCol: { span: 12 },
				data: {
					codeName: ''
				},
				rules: {
					codeName: [
						{
							required: true,
							whitespace: true,
							message: 'Please input CodeName'
						}
					]
				}
			}
		}
	},
	computed: {
		columns() {
			return [
				{
					// id
					title: 'ID',
					dataIndex: 'id',
					align: 'center'
				},
				{
					// 枚举名字
					title: 'Code Name',
					dataIndex: 'codeName',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('operate.operate'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiGetCodeList(initParams)
		},
		// 确定关闭弹窗
		async handleRoleModalOk() {
			await this.$refs.codeModalForm.validate()
			let params = {
				codeName: this.dictionaryModal.data.codeName
			}
			this.dictionary.loading = true
			apiCreateCode(params).then((res) => {
				this.dictionary.loading = false
				this.dictionary.show = false
				this.$refs.searchListTable.initTable(1)
			})
		},
		// 弹开弹窗
		showRoleModal(type) {
			this.dictionary.type = type
			this.dictionary.show = true
			this.dictionaryModal.data.codeName = ''
		},
		// 详情
		handleDetail(obj) {
			this.$router.push({
				path: 'dictionarydetail',
				query: {
					codeName: obj.codeName
				}
			})
		}
	}
}
</script>
<style scoped lang="less">
.btn {
	margin-right: 10px;
	margin-bottom: 20px;
}
</style>
